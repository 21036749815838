/**
 * Created by jerry on 2020/03/5.
 * 套餐卡分类api
 */
import * as API from '@/api/index'

export default {
    //套餐卡分类列表
    getPackageCardCategory: params => {
        return API.POST('api/packageCardCategory/all', params)
    },
    //新增套餐卡分类
    createPackageCardCategory: params => {
        return API.POST('api/packageCardCategory/create', params)
    },
    //编辑套餐卡分类
    updatePackageCardCategory:params => {
        return API.POST('api/packageCardCategory/update', params)
    },
    //套餐卡分类顺序调整
    movePackageCardCategory:params => {
        return API.POST('api/packageCardCategory/move', params)
    },

}